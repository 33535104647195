import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
const $size = require('lodash.size');
export const getActiveCategoryType = createAsyncThunk(
  'newLillaCategory/getActiveCategoryType',
  async () => {
    const response = await Catalog.activeCategoryType();
    return response.data;
  },
);

export const getPhaseCategoryHome = createAsyncThunk(
  'newLillaCategory/getPhaseCategoryHome',
  async () => {
    const response = await Catalog.getPhaseCategoryHome();
    return response.data;
  },
);

export const getGroupCategoryHome = createAsyncThunk(
  'newLillaCategory/getGroupCategoryHome',
  async () => {
    try {
      const response = await Catalog.getGroupCategoryHome();
      if ($size(response.data.data)) {
        response.data.data.forEach((data, keyData) => {
          if ($size(data.group_child)) {
            data.group_child.forEach((child, keyChild) => {
              if ($size(child.phase_tags)) {
                child.phase_tags.forEach((tag) => {
                  if (tag?.name === 'Pregnancy') {
                    response.data.data[keyData].group_child[
                      keyChild
                    ].position_pregnancy = Number(tag.position_on_home);
                  } else if (tag?.name === 'Breastfeeding') {
                    response.data.data[keyData].group_child[
                      keyChild
                    ].position_breastfeeding = Number(tag.position_on_home);
                  } else if (tag?.name === 'Newborn') {
                    response.data.data[keyData].group_child[
                      keyChild
                    ].position_newborn = Number(tag.position_on_home);
                  } else if (tag?.name === 'MPASI') {
                    response.data.data[keyData].group_child[
                      keyChild
                    ].position_mpasi = Number(tag.position_on_home);
                  } else if (tag?.name === 'Lil Ones') {
                    response.data.data[keyData].group_child[
                      keyChild
                    ].position_lil_ones = Number(tag.position_on_home);
                  } else if (tag?.name === 'Categories') {
                    response.data.data[keyData].group_child[
                      keyChild
                    ].position_categories = Number(tag.position_on_home);
                  }
                });
              }
            });
          }
        });
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getMultipleProducts = createAsyncThunk(
  'newLillaCategory/getMultipleProducts',
  async ({ groupSlug, params, type }) => {
    const response = await Catalog.getMultipleProducts({
      groupSlug,
      params,
      type,
    });
    return response.data;
  },
);

export const getMultipleProductsCount = createAsyncThunk(
  'newLillaCategory/getMultipleProductsCount',
  async ({ groupSlug, params, type }) => {
    const response = await Catalog.getMultipleProductsCount({
      groupSlug,
      params,
      type,
    });
    return response.data;
  },
);

export const loadMoreMultipleProducts = createAsyncThunk(
  'newLillaCategory/loadMoreMultipleProducts',
  async ({ groupSlug, params, type }) => {
    const response = await Catalog.getMultipleProducts({
      groupSlug,
      params,
      type,
    });
    return response.data;
  },
);
